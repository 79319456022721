const camera = function () {
    let width = 0;
    let height = 0;
    
    const createObjects = function () {
    
    
        const video = document.createElement('video');
        video.id = 'video';
        video.width = width;
        video.width = height;
        video.autoplay = true;
        let cameraContainer = document.getElementById("camera-container")
        cameraContainer.appendChild(video)
    
        const canvas = document.createElement('canvas');
        canvas.id = 'canvas';
        canvas.width = width;
        canvas.width = height;
        cameraContainer.appendChild(canvas)
    }
    
    
    return {
        video: null,
        context: null,
        canvas: null,
    
        startCamera: function (w = 680, h = 480) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                width = w;
                height = h;
    
                createObjects();
    
                this.video = document.getElementById('video');
                this.canvas = document.getElementById('canvas');
                this.context = this.canvas.getContext('2d');
                
                let constraints = {
                    video: {
                        width: {
                            min: 480,
                            ideal: 1024
                        },
                        height: {
                            min: 360,
                            ideal: 720
                        },
                        facingMode: "user"
                    },
                }
    
                (function (video) {
                    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                        video.srcObject = stream;
                        video.play();
                    });
                })(this.video)
    
            }
        },
    
    
        takeSnapshot: function () {
            this.context.drawImage(this.video, 0, 0, width, height);
        }
    
    }
    }();
    
    export default camera;