import React, { useState, useEffect } from "react";
import StepperForm from "./StepperForm";
import { getParams } from "../Utils";

const Form = () => {
    const [error, setError] = useState(false);
    const [canVideo, setCanVideo] = useState();
    const [contractId, setContractId] = useState();
    const [token, setToken] = useState();

    var getContractById = function (id, token) {
        const URL_CONTRACT =
            "https://becomedigital-api.azurewebsites.net/api/v1/contract/" + id;
        fetch(URL_CONTRACT, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }).then((response) => {
            console.log(response);
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                    if (data.id) {

                        // AQUÍ CAMBIAR A LA NUEVA PROPIEDAD DEL CONTRATO CON CHECK_LIVENESS
                        if (!data.canSendVideo) {
                            setError(true);
                        }
                    } else {
                        setError(true);
                    }
                });
            } else {
                setError(true);
            }
        });
    };

    useEffect(() => {
        let params = getParams(window.location.href);

        if (
            true
            // ("accessToken" in params &&
            //     "contractId" in params)
        ) {
            getContractById(params.contractId, params.accessToken);
            setContractId(params.contractId);
            setToken(params.accessToken);

        } else {
            setError(true);
        }
    }, []);
    return (
        <StepperForm
            error={error}
            contractId={contractId}
            token={token}
        />
    );
};

export default Form;
