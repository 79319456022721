import React, { Fragment, useEffect, useState } from "react";
import LoadingImg from "../../assets/images/load_init.gif";
import CheckMark from "../../assets/images/datos-enviados.svg";
import CancelImg from "../../assets/images/icon-falla.svg";
import { useHistory } from "react-router";
import { sendMessage } from "./../../Utils";

function ErrorList(props) {
  const errores = props.errores;
  const listItems = errores.map((err) => (
    <li key={err.documentError}>
      {err.documentError} - {err.code}
    </li>
  ));
  return <ul>{listItems}</ul>;
}

const FinalStep = (props) => {
  const [video, setVideo] = useState();
  const [isLoading, setIsloading] = useState();
  const [fetchResult, setFetchResult] = useState([]);

  let history = useHistory();

  const refreshPage = (e) => {
    e.preventDefault();
    window.location.replace(
      window.location.pathname + window.location.search + window.location.hash
    );
    // window.location.assign(window.location.href)
  };

  const closePage = (e) => {
    e.preventDefault();
    window.close();
  };

  useEffect(() => {
    setIsloading(true);

    setVideo([props.state.selfieVideo]);
    var files = new FormData();
    files.append("data", props.state.selfiePhoto[0], "selfiePhoto.jpg");

    const URL_VALIDATION =
      "https://idrnd.becomedigital.net/check_liveness";

    setTimeout(() => {

      fetch(URL_VALIDATION, {
        method: "POST",
        // headers: new Headers({
        //   Authorization: `Bearer ${props.token}`,
        // }),
        body: files,
      }).then((response) => {
        // console.log(response);
        response.json().then((data) => {
          setFetchResult(data);
          setIsloading(false);
        });
      });
    }, 1000);
  }, [props]);

  const handleFinishValidation = (e) => {
    e.preventDefault();
    sendMessage('userFinishedSdk', { "data": fetchResult, "photo": props.state.selfiePhoto[0] })
  };

  return (
    <Fragment>
      {isLoading ? (
        <div className="w-full h-full flex flex-col align-center justify-between py-20 md:p-8 fade-in">
          <img src={LoadingImg} alt="Loading..." className="lg:w-48 self-center" />
          <p className="text-center w-full mt-4">
            Estamos procesando sus datos...
          </p>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col align-center justify-between py-20 md:py-8 fade-in">
          <p className="text-center">Resultados de la verificación</p>
          {fetchResult.message === "El recurso fue creado" ? (
            <>
              <img
                src={CheckMark}
                alt="Check Mark"
                className="w-24 h-24 self-center"
              />
              <p className="text-center">
                Tus datos han sido enviados. Puedes cerrar esta página.
              </p>
              <button
                //onClick={() => props.handleOnClickNext()}
                onClick={handleFinishValidation}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
              >
                Fin de validación
              </button>
            </>
          ) : (
            <>
              <img
                src={CancelImg}
                alt="Cancel"
                className="w-12 md:h-24 h-12 md:h-24 self-center"
              />
              <p className="text-center">
                {fetchResult.message}. Contacte a su administrador de sistema.
              </p>
              {fetchResult.error ? (
                <>
                  <ul>
                    <ErrorList errores={fetchResult.error} />
                  </ul>
                  {fetchResult.message ===
                    "Hubo un problema con el envio de los archivos" ? (
                    <button
                      onClick={() => refreshPage()}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                    >
                      Intentar de nuevo
                    </button>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default FinalStep;
