import React, { Fragment, useState, useReducer, useEffect } from "react";
import Stepper from "./Stepper";
import DocumentType from "./steps/DocumentType";
import SelfiePhoto from "./steps/SelfiePhoto";
import BackDocument from "./steps/BackDocument";
import SelfieVideo from "./steps/SelfieVideo";
import SelfieVideoDesktop from "./steps/SelfieVideoDesktop";
import FinalStep from "./steps/FinalStep";
import Intro from "./steps/Intro";
import CloseSVG from "../assets/images/close.svg"
import { getDeviceType } from "../Utils";
import VerificationResult from "./steps/VerificationResult";

const complexStateInitial = {
  selfiePhoto: null,
  contractId: null,
  userId: null,
  token: null,
  documentType: "national-id",
};

const closeStyles = {
  maxWidth: '25px',
  position: 'absolute',
  alignSelf: 'flex-end'
}

function reducer(state, action) {
  switch (action.type) {
    case "frontDocument":
      return { ...state, frontDocument: action.value };
    case "backDocument":
      return { ...state, backDocument: action.value };
    case "selfieVideo":
      return { ...state, selfieVideo: action.value };
    case "documentType":
      return { ...state, documentType: action.value };
    case "userId":
      return { ...state, userId: action.value };
    case "token":
      return { ...state, token: action.value };
    case "contractId":
      return { ...state, contractId: action.value };
    case "selfiePhoto":
      return { ...state, selfiePhoto: action.value };

    // ... more states
    default:
      throw new Error();
  }
}

const StepperForm = (props) => {
  const [state, dispatch] = useReducer(reducer, complexStateInitial);
  const [activeStep, setActiveStep] = useState(1);
  const [frontImageDoc, setFrontImageDoc] = useState([]);
  const [video, setVideo] = useState([]);
  const [deviceType, setDeviceType] = useState();
  const [openFromIframe, setOpenFromIframe] = useState();
  const steps = [
    { title: "Intro" },
    { title: "Fotografia" },
    { title: "Final" }
  ];
  const videoIndex = steps
    .map(function (e) {
      return e.title;
    })
    .indexOf("Video Selfie");

  const backDocIndex = steps
    .map(function (e) {
      return e.title;
    })
    .indexOf("Documento Posterior");


  const handleOnClickNext = () => {
    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  const handleRepeatStep = () => { };

  // const handleOnClickBack = () => {
  //   let prevStep = activeStep - 1;
  //   setActiveStep(prevStep);
  // };

  const onChangeUseReducer = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  useEffect(() => {
    const deviceType = getDeviceType();
    window === window.parent ? setOpenFromIframe(false) : setOpenFromIframe(true);
    setDeviceType(deviceType);
    console.log(props.userId);
  }, []);

  const closePopUp = (e) => {
    e.preventDefault()

    sendMessage('exitedSdk', { "data": "user exited sdk" })

  }

  function sendMessage(action, payload = {}) {
    postMessage({ action, payload }, 1, '*');
  }

  function postMessage(message, id = 0, origin) {
    try {
      const parent = window.opener || window.parent;
      parent.postMessage(JSON.stringify({
        ...message,
        action: `ID-${id}::${message.action}`,
      }), origin);
    } catch (e) {
      console.error("Error: couldn't postMessage to parent window.", e);
    }
  }

  return (
    <Fragment>
      {!props.error ? (
        <div className="flex items-center justify-center w-full md:w-1/2 p-6 h-full">
          <div className="min-w-full w-full lg:w-1/2 min-h-full md:h-full">
            <form className="bg-white shadow-md px-8 pt-6 pb-8 mb-4 rounded-lg shadow-xl h-full flex items-center justify-center">
              <div className="w-full h-full flex items-center justify-center flex-col justify-between">
                {openFromIframe ? <button onClick={closePopUp} style={closeStyles}> <img src={CloseSVG}></img></button> : null}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Intro") + 1 ? (
                  <Intro
                    currentStep={activeStep}
                    handleChange={onChangeUseReducer}
                    documentType={state.documentType}
                    dispatch={dispatch}
                    steps={steps}
                    handleOnClickNext={handleOnClickNext}
                  />
                ) : null}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Fotografia") + 1 ? (
                  <SelfiePhoto
                    setFrontImageDoc={setFrontImageDoc}
                    currentStep={activeStep}
                    handleChange={onChangeUseReducer}
                    frontDocument={state.frontDocument}
                    steps={steps}
                    dispatch={dispatch}
                    handleOnClickNext={handleOnClickNext}
                  />
                ) : null}

                {activeStep ===
                  steps.map((e) => e.title).indexOf("Final") + 1 ? (
                  <FinalStep
                    currentStep={activeStep}
                    video={video}
                    steps={steps}
                    state={state}
                    country={props.country}
                    // handleSubmitAll={handleSubmitAll}
                    frontImageDoc={frontImageDoc}
                    userId={props.userId}
                    contractId={props.contractId}
                    token={props.token}
                    stateReq={props.stateReq}
                    canVideo={props.canVideo}
                    handleOnClickNext={handleOnClickNext}
                  />
                ) : null}

                <Stepper
                  steps={steps}
                  activeStep={activeStep}
                  // onSelect={num => { handleOnClickStepper(num) }}
                  showNumber={false}
                />
              </div>
            </form>
          </div>
        </div>
      ) : (
        "Hacen falta parámetros para iniciar el proceso de validación, contacte a su administrador"
      )}
    </Fragment>
  );
};

export default StepperForm;
