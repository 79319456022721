import React, { useState } from 'react';
import Webcam from "react-webcam";
import './styles/webcamStyles.css'
import SelfieStep from '../assets/images/selfie_step.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import WebcamCapture from './WebcamCapture';

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};

var constraints = {
    width: { ideal: 4096 },
    height: { ideal: 2160 },
    facingMode: "user"
};

export const WebcamComponent = (props) => {

    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);


    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc);



            setImage(imageSrc)
        });




    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }


    return (
        <div className="w-8/12 h-full flex justify-center items-center" style={{ margin: "0 auto" }}>
            <BrowserView className='w-full'>
                <div className="flex justify-center ">
                    {image == '' ? <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1}
                        mirrored={true}
                        videoConstraints={constraints}
                    /> : <img src={image} />}
                </div>
                <div className='mt-5'>
                    {image != '' ?
                        <div className='w-full flex justify-center w-2/4'>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setImage('')
                                }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">
                                Reintentar
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();

                                    let dataFile = dataURLtoFile(image, "selfiePhoto.jpg")
                                    props.dispatch({
                                        type: "selfiePhoto",
                                        value: [dataFile],
                                    });
                                    props.handleOnClickNext();
                                }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">
                                Siguiente
                            </button>
                        </div>
                        :
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                capture();
                            }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">
                            Capturar
                        </button>
                    }
                </div>
            </BrowserView>
            <MobileView>
                <WebcamCapture content="Documento posterior" handleOnClickNext={props.handleOnClickNext} dispatch={props.dispatch} setFrontImg={true} />
            </MobileView>
        </div>
    );
};

export default WebcamComponent 