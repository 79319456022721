import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  facingMode: "face",
};

const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  useEffect(() => {
    var camera = document.getElementById("camera");

    camera.addEventListener("change", function (e) {
      var file = e.target.files[0];
      console.log(file);
      var urlFile = URL.createObjectURL(file);
      console.log(urlFile);
      // Do something with the image file.

      props.dispatch({
        type: "selfiePhoto",
        value: [file],
      });

      props.handleOnClickNext();
    });
  }, []);

  return (
    <div className="relative w-64 mt-4 mb-4 self-center">
      <label className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full inline-flex items-center rounded text-center">
        <span className="text-base leading-normal text-center flex-grow">
          {props.content ? props.content : "Toma una foto."}
        </span>
        <input
          type="file"
          accept="image/*"
          capture="camera"
          id="camera"
          className="cursor-pointer hidden"
        ></input>
      </label>
    </div>
  );
};

export default WebcamCapture;
