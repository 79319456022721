import React, { Fragment, useState, useEffect } from "react";
import WebcamCapture from "../WebcamCapture";
import Webcam from "react-webcam";
import FrontImg from '../../assets/images/front_step.svg';
import WebcamComponent from "../WebcamCaptureNew";
import camera from "../camera";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';

const SelfiePhoto = (props) => {
  const [image, setImage] = useState('');
  const [frontImg, setFrontImg] = useState();

  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in" id="camera-container">
        <label
          className="block text-gray-700 text-lg font-bold mb-2"
          htmlFor="email"
        >
          Prueba de vida
        </label>

        <WebcamComponent dispatch={props.dispatch} handleOnClickNext={props.handleOnClickNext} />

      </div>
    </Fragment>
  );
};

export default SelfiePhoto;
